<template>
  <div>
    <b-card style="background-color:#e9e9e9">
      <b-container fluid>
        <div class="login-form login-signin">
          <div class="text-center mb-5 mb-lg-5">
            <strong style="color:gray;" class="font-size-h3"
              >استعادة كلمة السر</strong
            >
          </div>

          <!--begin::Form-->
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div
              role="alert"
              v-bind:class="{ show: errors.length > 0 }"
              class="alert fade alert-danger"
            >
              <div class="alert-text">
                {{ errors }}
              </div>
            </div>

            <b-form-group
              id="example-input-group-1"
              label="كلمة السر الجديدة"
              label-for="example-input-1"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-3 px-6"
                placeholder="كلمة السر الجديدة"
                type="password"
                id="example-input-1"
                name="example-input-1"
                v-model="$v.form.password.$model"
                :state="validateState('password')"
                aria-describedby="input-1-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-1-live-feedback">
                كلمة السر مطلوبة, ويجب أن تكون معقدة لا تقل عن 8 محارف
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              id="example-input-group-2"
              label="تأكيد كلمة السر"
              label-for="example-input-2"
            >
              <b-form-input
                class="form-control form-control-solid h-auto py-3 px-6"
                placeholder="تأكيد كلمة السر"
                type="password"
                id="example-input-2"
                name="example-input-2"
                v-model="$v.form.confirmPassword.$model"
                :state="validateState('confirmPassword')"
                aria-describedby="input-2-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="input-2-live-feedback">
                تأكيد كلمة السر مطلوب, ويجب أن يطابق كلمة السر
              </b-form-invalid-feedback>
            </b-form-group>

            <!--begin::Action-->
            <div
              class="form-group d-flex flex-wrap justify-content-between align-items-center"
            >
              <button
                ref="kt_login_signin_submit"
                style="background-color:gray;color:white;"
                class="btn font-weight-bold h-auto py-2 px-24"
              >
                حفظ
              </button>
            </div>
            <div
              class="text-center mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10"
            >
              <span class="font-weight-bold font-size-3 text-dark-60">
                <a
                  style="color:gray;"
                  class="font-weight-bold font-size-3 ml-5 mr-5"
                  @click="$router.push('/login')"
                >
                  <u>تسجيل الدخول</u>
                </a>
              </span>
            </div>
            <!--end::Action-->
          </b-form>
          <!--end::Form-->
        </div>
      </b-container>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import { RESET_PASSWORD, GET_USER } from "@/core/services/store/auth.module";

import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "ForgetPassword",
  data() {
    return {
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == null || from.name != "verifyForgetCode") {
      next("login");
    }
    next();
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty } = this.$v.form[name];
      if (name == "confirmPassword")
        return $dirty
          ? this.$v.form.confirmPassword.$model != this.$v.form.password.$model
            ? false
            : true
          : null;
      else
        return $dirty
          ? this.$v.form[name].$model.length < 6
            ? false
            : true
          : null;
    },
    resetForm() {
      this.form = {
        password: null,
        confirmPassword: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;
      const confirmPassword = this.$v.form.confirmPassword.$model;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      this.showCodeForm = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      setTimeout(() => {
        this.$store
          .dispatch(RESET_PASSWORD, {
            password: password,
            confirmPassword: confirmPassword
          })
          .then(() => {
            this.$store.dispatch(GET_USER).then(() => {
              this.$router.push({ name: "login" });
            });
          });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
